
.Tab .Tab-menu {
    padding: 20px 0px;
    margin-left: 0px;
}

.Tab .Tab-menu-el {
	cursor: pointer;
	color: #010101;
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    margin: 0px 24px 2px 0px;
    padding: 0px 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}

.Tab .Tab-menu-el {
    display: inline-block;
    width: inherit;
    max-width: inherit;
}

.Tab .Tab-menu-el:hover {
	background-color: rgba(0, 159, 227, 0.05);
}

.Tab .Tab-menu-el-selected {
    color: var(--primary-blue-80);
    border-bottom: 2px solid var(--primary-blue-80);
}

.Tab .Tab-menu-el-empty {
    height: 15px;
}