
#PageLandingLTAC {
	padding: 96px 0px;
}

#PageLandingLTAC .content {
	background: var(--linear-blue);
	margin: 80px 0px;
	padding: 50px 48px;
	border: 1px solid #E5F5FF;
	border-radius: 20px;
}

#PageLandingLTAC img {
	border-radius: 100%;
	width: 100%;
}