
.Resource {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-bottom: 32px;
    border: none;
}

.Resource .link {
    padding: 24px;
}

.Resource a:hover {
    text-decoration: none !important;
}

.Resource .link {
    color: inherit;
}

.Resource .card-body {
    padding: 0px !important;
}

.Resource .card-img-wrapper {
    min-height: 150px;
    position: relative;
}

.Resource .card-img-wrapper img,
.Resource .NoImage {
    border-radius: 12px;
    height: 214px !important;
    object-fit: cover;
}

.Resource .card-date {
    color: var(--primary-blue-80);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

.Resource .card-title {
    height: 54px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--text-neutral-50);
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 8px;
}

.Resource .card-text {
    height: 144px;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.Resource .card-icon {
    font-size: 48px;
    margin-bottom: 24px;
}

.Resource button {
   float: right;
}