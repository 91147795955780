.SimpleTable {
    line-height: 1.0;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.SimpleTable .SimpleTable-elements {
    height: calc(100% - 25px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.SimpleTable .SimpleTable-pagination {
    margin-bottom: 5px;
    height: 50px;
}

.SimpleTable .SimpleTable-pagination {
    display: flex;
    vertical-align: middle;
    justify-content: center;
}

.SimpleTable .SimpleTable-info {
    font-family: "Open Sans 600";
    font-size: 16px;
    bottom: 0px;
    width: auto;
    height: 60px;
    text-align: center;
    padding-top: 20px;
    margin: 0px  36px;
    flex-shrink: 0;
}

.SimpleTable .SimpleTable-arrowLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-blue-80);
    font-size: 20px;
    flex-shrink: 1;
    margin-top: 4px;
}

.SimpleTable .SimpleTable-arrowRight {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--primary-blue-80);
    font-size: 20px;
    flex-shrink: 1;
    margin-top: 4px;
}

.SimpleTable .SimpleTable-arrowLeft .iconDisabled {
    color: var(--text-neutral-10) !important;
    cursor: default !important;
}

.SimpleTable .SimpleTable-arrowRight .iconDisabled {
    color: var(--text-neutral-10) !important;
    cursor: default !important;
}

.SimpleTable .SimpleTable .row div p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}