
#PageSupportForStartupsPartners {
	padding: 64px 0px;
}

#PageSupportForStartupsPartners .title {
	background: var(--linear-blue);
	padding: 92px 70px 124px 70px;
	border: 1px solid #E5F5FF;
	border-radius: 24px;
	height: calc(100% - 24px);
}

#PageSupportForStartupsPartners .box {
	height: calc(100% - 24px);
	padding: 48px 24px;
	text-decoration: none !important;
}

#PageSupportForStartupsPartners a {
	text-decoration: none !important;
	color: inherit;
}