
#PageDashboardPublicSector .title {
	background: var(--linear-blue);
	padding: 134px 70px 164px 70px;
	border: 1px solid #E5F5FF;
	border-radius: 24px;
	height: calc(100% - 24px);
}

#PageDashboardPublicSector .total-institutions {
	padding: 0px 12px;
}

#PageDashboardPublicSector .BoxWithTitle img {
	width: 100%;
}

#PageDashboardPublicSector .BoxWithTitle img.hcpn-logo {
	margin: 20px 0px 30px 0px;
}

#PageDashboardPublicSector .BoxWithTitle .fixed-height {
	height: 90px;
}

#PageDashboardPublicSector .BoxWithTitle div:has(> img) {
	text-align: center;
}

#PageDashboardPublicSector .BoxWithTitle img.fixed-height {
	max-height: 90px;
	max-width: 100%;
	width: auto !important;
}

#PageDashboardPublicSector .education-training .h8 {
	height: 48px;
	display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 16px;
}

#PageDashboardPublicSector .header button {
	margin-bottom: 32px;
}

#PageDashboardPublicSector .framework-box i.fa-balance-scale,
#PageDashboardPublicSector .framework-box .h8 {
	display: table-cell;
	margin: 12px 0px 12px 0px;
}

#PageDashboardPublicSector .framework-box i.fa-balance-scale {
	float: left;
	font-size: 48px;
	color: var(--primary-blue-80);
	margin-right: 24px;
}

#PageDashboardPublicSector .framework-box .right-buttons button {
	margin: 24px 0px 0px 0px !important;
}

@media (min-width: 768px) {
	#PageDashboardPublicSector .education-training > .row > .col-md-12 > .row  > .col-md-4:first-child {
		border-right: 1px solid var(--text-neutral-10);
	}

	#PageDashboardPublicSector .education-training > .row > .col-md-12 > .row > .col-md-4:last-child {
		border-left: 1px solid var(--text-neutral-10);
	}
}

@media (max-width: 768px) {
	#PageDashboardPublicSector .total-institutions h3,
	#PageDashboardPublicSector .total-institutions h6 {
		vertical-align: middle;
		display: table-cell;
		width: auto;
		padding: 8px;
	}

	#PageDashboardPublicSector .total-institutions h3 {
		font-size: 64px !important;
	}
}