
#PageAbout .top-content {
	background: var(--linear-blue);
	padding: 60px 0px;
}

#PageAbout .top-content .blue-text {
	margin-bottom: 12px;
}

#PageAbout .top-content h4 {
	margin-bottom: 72px;
}

#PageAbout img {
	width: 100%;
}

#PageAbout .vertical-text {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

#PageAbout h5:not(:first-child) {
	margin: 48px 0px 32px 0px;
}

#PageAbout .menu {
	margin: 16px 0px;
}

#PageAbout .selected {
	border-left: 1px solid var(--primary-blue-80) !important;
	color: var(--primary-blue-80) !important;
}

#PageAbout .menu-element {
	padding: 12px;
	font-family: "Open Sans 600";
	font-size: 14px;
	text-transform: uppercase;
	border-left: 1px solid var(--text-neutral-10);
	color: var(--text-neutral-40);
}

#PageAbout .menu-element:hover {
	cursor: pointer;
	color: var(--primary-blue-80) !important;
	border-left: 1px solid var(--primary-blue-80) !important;
}