
.Field {
	margin-bottom: 16px;
}

.Field .label {
    padding: 8px;
    font-weight: 600;
	font-size: 20px;
	line-height: 150%;
}

.Field .wrong-format {
	border: 2px solid #e40613 !important;
}

.Field .wrong-format:focus {
	border: 3px solid #e40613 !important;
}

.Field .right-format {
	border: 2px solid #bcebff !important;
}

.Field .right-format:focus {
	border: 3px solid #bcebff !important;
}

.Field .chips {
	width: 100%;
}

.Field input[type='checkbox'] {
	display: flex;
	margin-right: 8px;
	text-align: left;
	width: auto;
	transform : scale(1.3);
	align-items: center;
	justify-content: center;
}

.Field .checkbox {
	display: flex;
	vertical-align: middle;
	font-weight: 400 !important;
}