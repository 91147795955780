@font-face { font-family: "Open Sans"; src: url('./font/OpenSans-Regular.ttf'); }
@font-face { font-family: "Open Sans 800"; src: url('./font/OpenSans-ExtraBold.ttf'); }
@font-face { font-family: "Open Sans 700"; src: url('./font/OpenSans-Bold.ttf'); }
@font-face { font-family: "Open Sans 600"; src: url('./font/OpenSans-SemiBold.ttf'); }

:root {
	--primary-blue: #1163EA;
    --primary-blue-10: #E5F5FF;
    --primary-blue-20: #CCEBFF;
    --primary-blue-30: #B3E0FF;
    --primary-blue-40: #99D6FF;
    --primary-blue-50: #80CCFF;
    --primary-blue-60: #66C2FF;
    --primary-blue-70: #4DB8FF;
    --primary-blue-80: #33ADFF;
    --primary-blue-90: #19A3FF;
    --primary-blue-100: #0099FF;

    --secondary-red: #1163EA;
    --secondary-red-10: #FCE6E7;
    --secondary-red-20: #F9CDD0;
    --secondary-red-30: #F7B4B8;
    --secondary-red-40: #F49BA1;
    --secondary-red-50: #F18289;
    --secondary-red-60: #EE6A71;
    --secondary-red-70: #EB515A;
    --secondary-red-80: #E93842;
    --secondary-red-90: #E61F2B;
    --secondary-red-100: #E30613;

    --text-neutral-0: #FFFFFF;
    --text-neutral-10: #D3D3D6;
    --text-neutral-20: #A7A7AD;
    --text-neutral-30: #7B7B84;
    --text-neutral-40: #4F4F5B;
    --text-neutral-50: #232332;

    --accent-red: #E93842;
    --accent-green: #337B49;
    --accent-black: #232332;
    --accent-orange: #EF7D38;
    --accent-blue: #33ADFF;

    --linear-blue: linear-gradient(180deg, #EBF7FF 0%, #FFFFFF 100%);
    --linear-blue-bottom: linear-gradient(286.87deg, #EBF7FF 40.41%, #FFFFFF 90.72%);
    --linear-red: linear-gradient(179.53deg, #FDEBEC 0.4%, #FFFFFF 43.91%);

    --gray-200: #EAECF0;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", "sans-serif";
    font-size: 16px;
    color: var(--text-neutral-40);
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

#App {
    width: 100%;
    height: 100%;
}

img {
    width: 100%;
}

h1, h2, h3, h4, h5, h6 {
	color: var(--text-neutral-50) !important;
}

h1 {
    font-family: "Open Sans 800";
    margin: 0px;
    padding: 0px;
    font-size: 56px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h2 {
    font-family: "Open Sans 800";
    margin: 0px;
    padding: 0px;
    font-size: 48px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h3 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h4 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h5 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 24px;
}

h6 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 24px;
}

.h7 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
}

.h8 {
    font-family: "Open Sans 700";
    margin: 0px;
    padding: 0px;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
}

.blue-text {
    color: var(--primary-blue-100) !important;
}

.uppercase {
    text-transform: uppercase;
}

.dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 10px;
    margin: 0px 8px;
}

.show-on-mobile {
    display: none;
}

@media (max-width: 768px) {
    .hide-on-mobile {
		display: none;
	}

    .show-on-mobile {
        display: inherit;
    }

    h1 {
        font-size: 32px !important;
    }

    h2 {
        font-size: 28px !important;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 22px !important;
    }

    h5 {
        font-size: 20px !important;
    }

    h6 {
        font-size: 18px !important;
    }
}

.max-sized-section {
    padding: 0px 16px;
	width: 100%;
	max-width: 1140px;
	margin: auto;
}

.mid-sized-section {
    padding: 0px 16px;
    width: 100%;
    max-width: 946px;
    margin: auto;
}

.sma-sized-section {
    padding: 0px 16px;
    width: 100%;
    max-width: 550px;
    margin: auto;
}

.spaced-row {
    margin-bottom: 24px;
}

.grey-horizontal-bar {
    height: 0px;
    width: 100%;
    border: 1px solid var(--text-neutral-10);
    margin-bottom: 24px;
}

.grey-vertical-bar {
    height: 100%;
    width: 1px;
    border: 1px solid var(--text-neutral-10);
    margin-bottom: 24px;
}

.grey-vertical-arrow-down {
    height: 100%;
    width: 1px;
    border: 1px solid var(--text-neutral-10);
    margin-bottom: 24px;
}

.quote {
    margin: 48px 0px;
    font-family: "Open Sans 600";
    font-size: 18px;
    font-style: italic;
    padding: 8px 20px;
    border-left: 2px solid var(--primary-blue-80);
}

.vertically-centered {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered {
    text-align: center;
}

.red {
    color: var(--secondary-red-80) !important;
}

.blue {
    color: var(--primary-blue-80) !important;
}

.top-content .text-content .h8 {
    margin-bottom: 12px;
}

strong, b {
    font-family: "Open Sans 700", "sans-serif";
}
