
#PageUpcomingEvents .top-content {
	position: relative;
	padding: 64px 0px;
}

#PageUpcomingEvents .top-content img {
	width: auto;
}

#PageUpcomingEvents .text-content {
	margin-bottom: 32px;
}

@media (min-width: 768px) {
	#PageUpcomingEvents .top-content .vertically-centered {
		justify-content: left !important;
	}

	#PageUpcomingEvents img.logo {
		height: calc(100% - 50px);
	}
}

@media (max-width: 768px) {
	#PageUpcomingEvents .top-content .buttons {
		text-align: center;
	}

	#PageUpcomingEvents .top-content .buttons button {
		display: inline-block;
		margin-bottom: 16px;
	}
}