
#PageEcosystemPublicSector .top-content {
	margin: 80px 0px;
}

#PageEcosystemPublicSector #PageEcosystemPublicSector-list-content .h8 {
	margin: 8px 0px;
}

#PageEcosystemPublicSector #PageEcosystemPublicSector-list-content span.h8 {
	margin-right: 8px;
}

#PageEcosystemPublicSector .vertically-centered h6 {
	width: 100%;
	margin-bottom: 12px;
}

#PageEcosystemPublicSector .SimpleTable {
	margin: 40px 0px;
}

#PageEcosystemPublicSector .list-separator {
	margin-bottom: 48px;
}

@media (max-width: 768px) {
	#PageEcosystemPublicSector .buttons {
		text-align: center;
	}

	#PageEcosystemPublicSector .buttons button {
		display: inline-block;
		margin-bottom: 16px;
	}
}