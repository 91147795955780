
#PageLandingCatch {
	position: relative;
	min-height: calc(100vh - 200px);
}

#PageLandingCatch .content {
	padding-top: 12vh;
	padding-bottom: 350px;
}

#PageLandingCatch .title {
	margin-bottom: 16px;
	font-style: normal;
	line-height: 120%;
}

#PageLandingCatch .catch-phrase {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 150%;
}

#PageLandingCatch .buttons {
	margin-top: 32px;
}

#PageLandingCatch .content button {
	width: 100%;
	margin-top: 14px;
	margin-bottom: 14px;
}

#PageLandingCatch .skyline {
	width: 100%;
	position: absolute;
	bottom: 120px;
	text-align: center;
}

#PageLandingCatch .skyline img {
	width: auto;
	margin: auto;
	max-width: 1440px;
	min-height: 100px;
}

#PageLandingCatch .max-sized-section {
	position: relative;
}

#PageLandingCatch .alert-container {
	width: 100%;
    display: flex;
	justify-content: center;
	align-items: center;
	bottom: 0px;
}

#PageLandingCatch .alert-wrapper {
	position: relative;
	max-width: 1140px;
	width: 100%;
	padding: 0px 10px;
}

#PageLandingCatch .alert-wrapper .AlertNews {
	position: absolute;
	bottom: -64px;
	right: 5px;
	width: 500px;
	z-index: 10;
}

#PageLandingCatch .alert-wrapper .PopupNews {
	position: absolute;
	bottom: -64px;
	right: 525px;
	width: 400px;
	z-index: 10;
	background-color: #33ADFF;
}

@media (min-width: 992px) {
    #PageLandingCatch .catch-phrase {
		max-width: 70%;
	}
}

@media (max-width: 992px) {
	#PageLandingCatch .content {
		padding-top: 12vh;
		padding-bottom: 168px;
	}

	#PageLandingCatch .alert-container,
	#PageLandingCatch .alert-wrapper {
		position: relative;
		display: block !important;
		width: 100%;
		bottom: unset;
	}

	#PageLandingCatch .alert-wrapper .AlertNews {
		position: relative !important;
		width: 100% !important;
		bottom: unset;
		right: unset;
		margin-bottom: 12px;
	}

	#PageLandingCatch .alert-wrapper .PopupNews {
		position: relative !important;
		width: 100% !important;
		bottom: unset;
		right: unset;
		margin-bottom: 12px;
	}
}

@media (max-width: 992px) {
	#PageLandingCatch .content {
		padding-top: 6vh;
	}
}