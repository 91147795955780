
#GovBar {
    background: #fff;
    border-bottom: 1px solid #cccaca;
    font-family: Helvetica,Arial,sans-serif;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    height: 46px;
    width: 100%;
    z-index: 200;
}

.govbar-logo {
    border: 0;
    float: left;
    padding: 3px 8px;
}

.govbar-links {
    float: right;
    line-height: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.govbar-links li {
    float: left;
    position: relative;
    padding-bottom: 2px;
    padding-bottom: .2rem;
    padding-left: 15px;
    padding-left: 1.5rem;
}

.govbar-link {
    float: left;
    font-weight: bold;
    padding: 8px;
}

.govbar-link:hover, .govbar-link:focus {
    background: #e6e6e6 !important;
}

.govbar-link:link, .govbar-link:visited, .govbar-link:hover, .govbar-link:focus, .govbar-link:active {
    border: 0;
    color: #222 !important;
    text-decoration: none;
}