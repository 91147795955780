
#PageDashboardOverview .title {
	background: var(--linear-blue);
	padding: 134px 70px 164px 70px;
	border: 1px solid #E5F5FF;
	border-radius: 24px;
	height: calc(100% - 24px);
}

#PageDashboardOverview .total-entities {
	padding: 24px;
}

#PageDashboardOverview .total-entities h2 {
	margin-bottom: 56px;
}

#PageDashboardOverview .governance-box-content {
	text-align: center;
}

#PageDashboardOverview .governance-box-content .grey-vertical-bar,
#PageDashboardOverview .governance-box-content .grey-vertical-arrow-down {
	height: 50px !important;
	margin: 6px auto 12px auto;
}

#PageDashboardOverview .governance-box img {
	width: 100%;
}

#PageDashboardOverview .governance-box .governance-box-content h1,
#PageDashboardOverview .governance-box .governance-box-content h5 {
	margin-bottom: 36px;
}

#PageDashboardOverview .governance-box .right-buttons {
	margin-top: 24px;
}

#PageDashboardOverview .sector-box i.fas-landmarks {
	font-size: 60px;
}

#PageDashboardOverview .sector-box-logo {
	text-align: center;
}

#PageDashboardOverview .sector-box-logo i {
	font-size: 80px;
	margin: 10px 0px 30px 0px;
	color: var(--text-neutral-50)
}

#PageDashboardOverview .donut-wrapper {
	aspect-ratio: 1 / 1;
}

#PageDashboardOverview .big-donut,
#PageDashboardOverview .small-donut {
	position: absolute;
}

#PageDashboardOverview .donut-description {
	padding: 24px 0px;
}

#PageDashboardOverview .donut-description h3 {
	margin-bottom: 0px;
}

#PageDashboardOverview .donut-description > div {
	margin-bottom: 12px;
}

@media (max-width: 768px) {
	#PageDashboardOverview .donut-description {
		text-align: center;
	}

	#PageDashboardOverview .total-entities * {
		vertical-align: middle;
		display: table-cell;
		width: auto;
		padding: 8px;
	}

	#PageDashboardOverview .total-entities h2 {
		height: auto;
		font-size: 64px !important;
	}
}