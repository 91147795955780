.NoImage {
    width: 100%;
    height: 100%;
    position: relative;
    background: repeating-linear-gradient(
      135deg,
      var(--text-neutral-10),
      var(--text-neutral-10) 10px,
      rgba(0, 0, 0, 0) 10px,
      rgba(0, 0, 0, 0) 20px
    );
}

.NoImage-logo {
    font-size: 50px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    line-height: 1 !important;
}

.NoImage-logo i {
    color: var(--text-neutral-10);
    width: 50px;
    height: 50px;
}