
.Footer {
  width: 100%;
  z-index: 150;
  padding: 96px 16px 96px 16px;
}

.Footer img {
  width: 100%;
}

.Footer .content {
  margin: 0px auto 0px auto;
  max-width: 1140px;
}

.Footer > .content > .row > div {
  margin-bottom: 24px;
}

.Footer .patronage {
  font-size: 14px;
  margin: 12px 0px;
  color: var(--text-neutral-50);
  text-align: justify;
}

.Footer .key-partners {
  font-family: "Open Sans 600";
  color: var(--text-neutral-50);
  margin-bottom: 16px;
}

.Footer a {
  color: var(--text-neutral-40);
}

.Footer .partner-link {
  font-size: 14px;
  color: var(--text-neutral-50);
  text-decoration: underline;
  margin-bottom: 8px !important;
}

.Footer .link {
  font-size: 14px;
  padding-bottom: 16px;
  font-family: "Open Sans 600";
}

.Footer a:hover {
  color: red;
}

.Footer .title {
    font-family: "Open Sans 600";
    color: var(--primary-blue-80);
    font-style: normal;
    padding-bottom: 16px;
}

.Footer .Footer-network {
  font-size: 20px;
  margin: 0px 8px;
  text-align: center;
  color: #009fe3;
}

.Footer .social-network {
  position: relative;
  margin-top: 24px !important;
  margin-bottom: 34px !important;
}

.Footer .social-network a > * {
  margin-right: 16px;
}

.Footer .social-network a > img {
  max-width: 17px !important;
  vertical-align: text-top;
}

.Footer .row > div {
  padding-right: 20px;
}

.Footer button {
  font-family: "Open Sans 600";
  font-size: 14px;
}