
#PageDashboardPrivateSector .title {
	background: var(--linear-blue);
	padding: 134px 70px 164px 70px;
	border: 1px solid #E5F5FF;
	border-radius: 24px;
	height: calc(100% - 24px);
}

#PageDashboardPrivateSector .header button {
	margin-bottom: 32px;
}

#PageDashboardPrivateSector .header .box img {
	max-height: 100%;
	max-width: 100%;
}

#PageDashboardPrivateSector .header .box div:has(> img) {
	text-align: center;
}

#PageDashboardPrivateSector .Tab .Tab-menu {
	width: fit-content;
	text-align: left;
	border: 1px solid var(--gray-300, #D0D5DD);
	border-radius: 6px;
	padding: 0px;
	margin-bottom: 24px;
}

#PageDashboardPrivateSector .Tab .Tab-menu-el {
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	padding: 10px 16px;
	margin: 0px;
	overflow: unset;
}

#PageDashboardPrivateSector .Tab .Tab-menu-el:not(:last-child) {
	border-right: 1px solid var(--gray-300, #D0D5DD);
}

#PageDashboardPrivateSector .Tab .Tab-menu-el-selected {
	border: none;
}

@media (max-width: 768px) {
	#PageDashboardPrivateSector .total-institutions h3,
	#PageDashboardPrivateSector .total-institutions h6 {
		padding: 8px;
	}

	#PageDashboardPrivateSector .total-institutions h3 {
		font-size: 64px !important;
	}

	#PageDashboardPrivateSector .Tab .Tab-menu {
		margin-left: auto;
		margin-right: auto;
	}
}