
#PageSupport .immediate-support {
	margin: 64px 0px;
	padding: 40px 16px;
	background: var(--linear-red);
	border-radius: 24px;
}

#PageSupport .immediate-support p {
	text-align: center;
	margin-bottom: 40px;
}


#PageSupport .immediate-support h2 {
	color: var(--secondary-red-80) !important;
	text-align: center;
}

#PageSupport .immediate-support .h8 {
	margin-bottom: 8px;
}

#PageSupport .immediate-support .Field div[class$="-singleValue"] {
	color: var(--primary-blue-80) !important;
}

#PageSupport .immediate-support button {
	width: 100%;
}

#PageSupport .report {
	margin-top: 96px;
	padding: 50px 24px;
	border: 1px solid #F49BA1;
	border-radius: 20px;
}

#PageSupport .report button {
	margin-top: 32px;
	color: var(--secondary-red-80) !important;
}

#PageSupport .report .report-img {
	height: 100%;
	display: flex;
    justify-content: center;
  	align-items: center;
}

#PageSupport .report .report-img img {
	width: 100%;
	height: fit-content;
}