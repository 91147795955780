
#PageLandingNewsletter {
	padding: 96px 0px;
}

#PageLandingNewsletter .content {
	background: var(--linear-blue);
	margin: 80px 0px;
	padding: 50px 48px;
	border: 1px solid #E5F5FF;
	border-radius: 20px;
}

#PageLandingNewsletter .row div:has(button) {
	display: flex;
	align-items: center;
}

#PageLandingNewsletter .row div:has(button) button {
	margin: auto;
}