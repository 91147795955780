
#PageStandards .top-content {
	background: var(--linear-blue);
	padding: 60px 0px;
	margin-bottom: 64px;
}

#PageStandards .vertically-centered h6 {
	width: 100%;
	margin-bottom: 12px;
}

#PageStandards .list-content .h8 {
	margin: 8px 0px;
}

#PageStandards .list-content span.h8 {
	margin-right: 8px;
}

#PageStandards .DynamicTable {
	margin: 40px 0px;
}

#PageStandards .list-separator {
	margin-bottom: 48px;
}