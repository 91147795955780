
#PageBestPracticeTestImprove {
	border-top: 1px solid var(--text-neutral-10);
	padding: 40px 0px 48px 0px;
}

#PageBestPracticeTestImprove .menu {
	margin: 16px 0px;
}

#PageBestPracticeTestImprove .selected {
	border-left: 1px solid var(--primary-blue-80) !important;
	color: var(--primary-blue-80) !important;
}

#PageBestPracticeTestImprove .menu-element {
	padding: 12px;
	font-family: "Open Sans 600";
	font-size: 14px;
	text-transform: uppercase;
	border-left: 1px solid var(--text-neutral-10);
	color: var(--text-neutral-40);
}

#PageBestPracticeTestImprove .menu-element:hover {
	cursor: pointer;
	color: var(--primary-blue-80) !important;
	border-left: 1px solid var(--primary-blue-80) !important;
}

#PageBestPracticeTestImprove .grey-horizontal-bar {
	margin: 32px 0px;
	border-color: var(--gray-200);
}

#PageBestPracticeTestImprove .Tool-section {
	margin-top: 16px;
	margin-bottom: 48px;
}