
.AlertNews {
    border-radius: 12px;
    background: var(--secondary-red-80, #E93842);
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.16);
    padding: 16px;
    color: var(--text-neutral-white, #FFF);
}

.AlertNews .title {}

.AlertNews .close-button {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
}

.AlertNews .right-buttons {
    margin-top: 8px;
}

.AlertNews button {
    color: var(--text-neutral-white, #FFF) !important;
    margin-bottom: 0px;
}

.AlertNews .slider {
    display: flex;
    margin-top: 16px;
}

.AlertNews .slider > div {
    flex: 0 auto 0;
    flex: auto;
    padding: 8px;
}

.AlertNews .slider .slider-content {
    flex: 1 1 auto;
}

.AlertNews .slider .slider-content span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.AlertNews .slider .slider-left-arrow,
.AlertNews .slider .slider-right-arrow {
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.AlertNews .slider .slider-left-arrow i,
.AlertNews .slider .slider-right-arrow i {
    cursor: pointer;
}


.AlertNews .slider .slider-left-arrow i.disabled,
.AlertNews .slider .slider-right-arrow i.disabled {
    cursor: default;
    color: var(--text-neutral-10);
}

.AlertNews-hidden {
    visibility: hidden;
}