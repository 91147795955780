
#PageLatestNews .top-content {
	position: relative;
	padding: 64px 0px;
}

#PageLatestNews .top-content img {
	width: auto;
}

#PageLatestNews .text-content {
	margin-bottom: 32px;
}

@media (min-width: 768px) {
	#PageLatestNews .top-content .vertically-centered {
		justify-content: left !important;
	}

	#PageLatestNews img.logo {
		height: calc(100% - 50px);
	}
}

#PageLatestNews .podcast-content {
	margin: 96px 0px;
	padding: 96px 0px 48px 0px;
    background: var(--linear-blue-bottom);
}

#PageLatestNews .podcast-content img {
	width: 100%;
	margin-bottom: 48px;
}

#PageLatestNews .podcast-content button {
	margin-top: 40px;
	margin-bottom: 40px;
}

@media (max-width: 768px) {
	#PageLatestNews .top-content .buttons {
		text-align: center;
	}

	#PageLatestNews .top-content .buttons button {
		display: inline-block;
		margin-bottom: 16px;
	}
}