
.ChartSolutionStartupHorizontalBar .label {
	font-size: 14px;
	margin-bottom: 8px;
}

.ChartSolutionStartupHorizontalBar .legend {
	margin-top: 40px;
	font-family: "Open Sans 600";
	text-transform: uppercase;
	font-size: 12px;
	line-height: 150%;
}

