
#PageSupportForStartupsNews {
	padding: 32px 0px 64px 0px;
}

#PageSupportForStartupsNews .DynamicTable {
	margin: 40px 0px;
}

#PageSupportForStartupsNews span.h8 {
	margin-right: 8px;
}