
.Entity {
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    border: none;
    margin-bottom: 32px;
}

.Entity .card-horizontal {
    height: 100%;
    display: flex;
    flex: 1 1 auto;
}

.Entity .img-square-wrapper {
    display: flex;
    margin: auto;
    height: 94px;
    flex: 0 0 94px;
}

.Entity .img-square-wrapper img {
    object-fit: contain;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.Entity .card-body .name {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 12px;
    font-size: 16px;
    line-height: 20px;
}

.Entity .buttons {
    text-align: right;
}

.Entity button {
    font-size: 14px;
}