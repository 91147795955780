
.Event {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    margin-bottom: 32px;
    border: none;
}

.Event .link {
    padding: 16px;
}

.Event a:hover {
    text-decoration: none !important;
}

.Event .link {
    color: inherit;
}

.Event .card-body {
    padding-right: 0px !important;
    padding-left: 0px;
    padding-bottom: 0px !important;
}

.Event .card-img-wrapper {
    min-height: 150px;
    position: relative;
}

.Event .card-img-wrapper img,
.Event .NoImage {
    border-radius: 12px;
    height: 214px !important;
    object-fit: cover;
}

.Event .card-date {
    color: var(--secondary-red-80);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
}

.Event .card-date i {
    margin: 2px 6px 2px 2px;
}

.Event .card-title {
    height: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: var(--text-neutral-50);
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 8px;
}

.Event .card-text {
    height: 72px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.Event button {
   float: right;
}