
#PageDashboard .header-content {
	position: relative;
	background: var(--linear-blue);
	margin: 64px 0px 40px 0px;
	padding: 60px 94px 164px 94px;
	text-align: center;
}

#PageDashboard .header-content img {
	position: absolute;
	left: 0px;
	bottom: -20px;
	width: 100%;
}

#PageDashboard .Tab-menu {
	text-align: center;
	margin-bottom: 40px;
}

#PageDashboard .Tab-menu-el {
	font-size: 24px;
	text-transform: none;
	color: var(--black-40);
}

#PageDashboard .Tab-menu-el-selected {
	border-bottom: 4px solid var(--primary-blue-80);
	color: var(--primary-blue-80);
}

@media (max-width: 992px) {
	#PageDashboard .header-content {
		position: relative;
		padding: 60px 48px 164px 48px;
	}

	#PageDashboard .header-content img {
		position: absolute;
		left: 0px;
		bottom: 20px;
		width: 100%;
	}
}
