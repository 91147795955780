
#PageEcosystemInitiatives .top-content {
	margin: 80px 0px;
}

@media (min-width: 768px) {
	#PageEcosystemInitiatives .top-content .vertically-centered {
		justify-content: left !important;
	}

	#PageEcosystemInitiatives img.logo {
		height: calc(100% - 100px);
		width: auto;
	}
}

#PageEcosystemInitiatives .vertically-centered h6 {
	width: 100%;
	margin-bottom: 12px;
}

#PageEcosystemInitiatives #PageEcosystemInitiatives-list-content .h8 {
	margin: 8px 0px;
}

#PageEcosystemInitiatives #PageEcosystemInitiatives-list-content span.h8 {
	margin-right: 8px;
}

#PageEcosystemInitiatives .SimpleTable {
	margin: 40px 0px;
}

#PageEcosystemInitiatives .list-separator {
	margin-bottom: 48px;
}

@media (max-width: 768px) {
	#PageEcosystemInitiatives .buttons {
		text-align: center;
	}

	#PageEcosystemInitiatives .buttons button {
		display: inline-block;
		margin-bottom: 16px;
	}
}