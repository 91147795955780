
.Menu {
    padding-top: 12px;
    padding-bottom: 12px;
}

@media (max-width: 480px) {
    .Menu .Menu-hide-on-mobile {
        display: none;
    }
}

.Menu .logo {
    max-height: 120px;
    width: auto;
    margin-bottom: 4px;
}

.Menu .top-link .Menu-title {
    font-family: "Open Sans 700" !important;
    font-size: 14px;
    line-height: 150%;
    vertical-align: center;

    text-align: center;
    text-transform: uppercase;

    margin-right: 24px;
}

.Menu .top-link .Menu-title {
    font-family: "Open Sans 700" !important;
    font-size: 14px;
    line-height: 150%;
    vertical-align: center;

    text-align: center;
    text-transform: uppercase;

    margin-right: 24px;
}

.Menu .top-link .Menu-title i {
    margin-right: 7px;
}

.Menu .top-link a {
    color: var(--primary-blue-100) !important;
}

.Menu .separator {
    margin-right: 24px;
    width: 1px;
    background-color: var(--text-neutral-10);
}

.Menu .navbar {
    display: block;
    z-index: 10;
    padding: 0 !important;
}

.Menu .navbar a {
    text-decoration: none;
}

.Menu .navbar a:hover {
    color: var(--primary-blue-100) !important;
}

.Menu .main-navbar-nav {
    margin-left: 5px !important;
}

.Menu .main-navbar-nav .Menu-title {
    color: var(--text-neutral-50);
}

/* DROPDOWN */

.Menu .navbar-toggler {
    background-color: white;
    opacity: 1;
    border: none;
    box-shadow: none;
}

.Menu .navbar-toggler-icon {
    color: var(--primary-blue-80);
}

.Menu .nav-item {
    margin-left: 2px !important;
    margin-right: 16px !important;
}

.Menu .nav-item .dropdown-menu {
    border: 1px solid var(--primary-blue-10);
    box-shadow: 0px 8px 16px rgba(155, 153, 152, 0.16);
    border-radius: 8px;
    padding: 24px;
}

.Menu .nav-item .dropdown-item {
    background-color: transparent;
}

.Menu .nav-item .dropdown-menu {
    min-width: 510px;
}

.Menu .nav-item-about .dropdown-menu {
    min-width: 250px !important;
}

.Menu .nav-item .dropdown-menu .dropdown-item:hover {
    color: #000;
}

.Menu .Menu-item {
    position: relative;
}

.Menu .Menu-logo {
    max-width: 100%;
    width: 400px;
}

.Menu .Menu-image {
    display: flex;
    float: left;
    height: 50px;
    width: 37px;
    margin-right: 5px;
}

.Menu .Menu-image img {
    width: 35px;
}

.Menu .Menu-image-text {
    padding-right: 40px;
}

.Menu .dropdown-item {
    padding: 8px 0px;
}

.Menu .dropdown-item .Menu-title {
    font-family: "Open Sans";
    font-weight: 400 !important;
    margin-bottom: 16px;
    white-space: initial;
}

.Menu .dropdown i {
    display: inline-block;
    color: var(--text-neutral-50);
    font-size: 12px;
    margin-left: 8px;
}

.Menu .dropdown-toggle::after {
    display: none;
}

.Menu .dropdown .h8 {
    font-family: "Open Sans 700";
    text-transform: uppercase;
    margin-bottom: 16px;
}

.Menu .dropdown .sub-box {
    border-left: 1px solid var(--text-neutral-10);
    padding-left: 8px;
    margin: 4px 0px;
}

.Menu .dropdown .sub-box .dropdown-item {
    padding: 4px 0px;
}


/* MENU STRUCTURE */

.Menu-title {
    display: inline;
    font-family: "Open Sans 700";
    font-size: 16px;
    line-height: 150%;
}

.Menu .top-right-bar {
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
}

@media (max-width: 992px) {
    .Menu .navbar-brand {
        position: relative;
        width: 100% !important;
    }

    .Menu .navbar-brand .logo {
        width: 400px !important;
        max-width: calc(100% - 100px);
        height: auto;
        max-height: none;
    }

    .Menu .top-right-bar {
        margin-top: 15px;
        width: 100%;
    }

    .Menu .top-right-bar .immediate {
        margin: 16px auto;
        width: fit-content;
        box-sizing: border-box;

        /* Auto layout */

        flex-direction: row;
        align-items: center;

        padding: 16px 24px;
        gap: 19px;
        position: relative;
        font-size: 16px;
        line-height: 150%;
        color: var(--text-neutral-0);

        background: var(--accent-blue);
        border: 1px solid var(--accent-blue) --primary-blue-100;

        /* Button drop shadow */

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
        border-radius: 200px;
    }

    .Menu .split-bar {
        margin: 24px 0px;
        width: 100%;
        height: 1px;
        background-color: var(--text-neutral-10);
    }

    .Menu .nav-link {
        padding: 12px 16px;
        margin-bottom: 8px;
    }

    .Menu .navbar-toggler {
        position: absolute;
        right: 20px;
        top: calc(50% - 30px);
    }
}

@media (min-width: 992px) {
    .Menu .top-right-bar {
        position: absolute;
        margin-top: 0px;
        top: 45px;
        right: 5px;
    }

    .Menu-title {
        text-align: left;
    }

    .Menu .split-bar {
        display: none;
    }
}
