
#PageEcosystemPrivateSector .top-content {
	margin: 80px 0px;
}

#PageEcosystemPrivateSector .buttons button {
	margin-bottom: 16px;
}

#PageEcosystemPrivateSector #PageEcosystemPrivateSector-list-content .h8 {
	margin: 8px 0px;
}

#PageEcosystemPrivateSector #PageEcosystemPrivateSector-list-content span.h8 {
	margin-right: 8px;
}

#PageEcosystemPrivateSector .vertically-centered h6 {
	width: 100%;
	margin-bottom: 12px;
}

#PageEcosystemPrivateSector .SimpleTable {
	margin: 40px 0px;
}

#PageEcosystemPrivateSector .list-separator {
	margin-bottom: 48px;
}

#PageEcosystemPrivateSector .trigger:has(.classification-trigger) {
	margin-left: 4px;
	display: inline-block;
	color: var(--primary-blue-80);
	cursor: pointer;
}

@media (max-width: 768px) {
	#PageEcosystemPrivateSector .buttons {
		text-align: center;
	}

	#PageEcosystemPrivateSector .buttons button {
		display: inline-block;
		margin-bottom: 16px;
	}
}