
#PageDashboardPrivateSectorStartups .discover-program {
	background: var(--linear-blue);
	padding: 50px 48px;
	border: 1px solid #E5F5FF;
	border-radius: 24px;
}

#PageDashboardPrivateSectorStartups .discover-program .image-wrapper {
	display: flex;
	align-items: center;
	background-color: white;
	border-radius: 100%;
	width: 100%;
	aspect-ratio: 1 / 1;
	overflow: hidden;
}

#PageDashboardPrivateSectorStartups .discover-program .image-wrapper img {
	width: 100%;
	height: fit-content;
}
