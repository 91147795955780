
#SectionCSWL {
	padding: 96px 0px;
}

#SectionCSWL .content {
	background: var(--linear-blue);
	margin: 80px 0px;
	padding: 50px 48px;
	border: 1px solid #E5F5FF;
	border-radius: 20px;
}

#SectionCSWL .text-content {
	height: 100%;
	display: flex;
	align-items: center;
}

#SectionCSWL .catch-phrase {
	margin-bottom: 32px;
}

#SectionCSWL img {
	border-radius: 100%;
	width: 100%;
}